<div class="container bg-white mt-2 item-edit">
    <div>
        <h2 class="news__title">Nieuws {{reserve ? 'toevoegen' : 'wijzigen'}}</h2>
    </div>
    <div *ngIf="form">
        <form [formGroup]="form">
            <div (click)="fileManagerMain.chooseSource()"
                 class="main-image">
                <app-codalt-image [path]="form.value.id+'/'+form.value?.image"
                                  [thumb]="article.image_thumb">
                </app-codalt-image>
                <file-manager #fileManagerMain
                              (callback)="uploadedMainImage($event)"
                              (loading)="uploadMainImage($event)"
                              [class.text-white]="isCordova && form.controls['image'].value"
                              [currentFile]="form.controls['image'].value"
                              [directUpload]="true"
                              [path]="form.value.id"
                              [remove]="true"
                              icon="{{isCordova ? 'fas fa-image' : null}}"
                              secure="article">
                </file-manager>
            </div>
            <div class="row">
                <mat-form-field class="col-auto flex-grow-1 mt-3">
                    <mat-label>Titel</mat-label>
                    <input formControlName="title" matInput type="text">
                    <mat-error [control]="form.get('title')"></mat-error>
                </mat-form-field>
                <mat-checkbox class="col-auto mt-4" formControlName="alert">Belangrijk bericht</mat-checkbox>
            </div>
            <div class="w-100 col-date">
                <mat-form-field (click)="picker.open()" class="w-50">
                    <mat-label>Publicatiedatum</mat-label>
                    <input [matDatepicker]="picker" formControlName="publish_date" matInput>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error [control]="form.get('publish_date')"></mat-error>
                </mat-form-field>
                <mat-form-field class="w-50 pl-2">
                    <mat-label>Publicatietijd</mat-label>
                    <input formControlName="publish_time" matInput placeholder="hh:mm" type="text">
                    <mat-error [control]="form.get('publish_time')"></mat-error>
                </mat-form-field>
            </div>
            <div>
                <b>Beschrijving: </b>
                <simple-tiny [content]="form.get('content')"
                             elementId="content"
                             ngDefaultControl
                             propertyName="value"
                ></simple-tiny>
                <mat-error [control]="form.get('content')"></mat-error>
            </div>
            <div class="mt-2 mb-2" *ngIf="LocalStorage.school.replies">
                <b>Reacties: </b>
                <div>
                    <mat-checkbox formControlName="replies">Reacties inschakelen</mat-checkbox>
                </div>
            </div>
            <div class="mt-2 mb-2">
                <b>Zichtbaarheid: </b>
                <div>
                    <mat-radio-group formControlName="access_level">
                        <mat-radio-button *ngIf="AuthorisationService.hasFeature('publicArticles')" class="mr-2" value="PUBLIC">
                            Website & App
                        </mat-radio-button>
                        <mat-radio-button class="mr-2" value="LOGGEDIN">App (ouders en medewerkers)</mat-radio-button>
                        <mat-radio-button class="mr-2" value="EMPLOYEE">App alleen medewerkers</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="!masterSchool && form.get('access_level')?.value !== 'PUBLIC' && form.get('access_level')?.value !== 'EMPLOYEE'">
                <b>Voor groepen: </b>
                <div>
                    <mat-radio-group formControlName="schoolWide" *ngIf="AuthorisationService.hasFeature('publicArticles')">
                        <mat-radio-button class="mr-2" [value]="true">Schoolbreed</mat-radio-button>
                        <mat-radio-button class="mr-2" [value]="false">Specifieke groepen</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-selection-list *ngIf="classrooms && !form.get('schoolWide').value" class="classroom-list" formControlName="classrooms">
                    <mat-list-option *ngFor="let classroomGroup of classroomGroups" [value]="'group_' + classroomGroup" title="{{classroomGroup}}" togglePosition="before">
                        {{classroomGroup}}
                    </mat-list-option>
                    <mat-list-option *ngFor="let classroom of classrooms"
                                     matTooltip="Dit is een groep van een voorgaand schooljaar en kan niet meer worden aangepast."
                                     [matTooltipDisabled]="!classroom.deleted_at"
                                     [disabled]="classroom.deleted_at"
                                     [value]="classroom.id"
                                     title="{{classroom.classroom_name}}"
                                     togglePosition="before">
                        {{classroom.classroom_name}}
                    </mat-list-option>
                </mat-selection-list>
                <mat-error *ngIf="form.get('classrooms').touched" [control]="form.get('classrooms')"></mat-error>
            </div>
            <div class="row mt-3 mb-3" *ngIf="permissions?.length > 0">
                <div class="col-24">
                    <b>Toestemmingen</b>
                    <ng-container *ngFor="let question of permissions">
                        <mat-card class="mt-2 mb-3" *ngIf="question.permissions?.length">
                            <mat-card-content>
                                <h4 class="d-flex align-items-center">
                                    {{question.name}}
                                    <button (click)="info(question)" mat-icon-button color="primary">
                                        <mat-icon class="fas fa-info-circle"></mat-icon>
                                    </button>
                                </h4>
                                <div *ngFor="let level of question.options" class="pb-2">
                                    <div class="mb-2">
                                        <b>{{level.name}}</b>, <i>{{level.intro}}</i>
                                    </div>
                                    <div class="ml-3" *ngFor="let classroom of classrooms">
                                        <ng-container *ngIf="(question.permissions | filterPermissionsPipe : classroom.id : level.name : form.get('classrooms').value).length">
                                            <b *ngIf="classrooms">{{classroom.classroom_name}}</b>: -
                                            <ng-container
                                                *ngFor="let permission of ((question.permissions | filterPermissionsPipe : classroom.id : level.name : form.get('classrooms').value) | orderBy : 'student.name'); let last = last">
                                                {{permission?.student?.name}}{{last ? '' : ','}}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="masterSchool">
                <b>Toon dit bericht bij: </b><br>
                <mat-selection-list formControlName="schools" class="school-list" *ngIf="schools">
                    <mat-list-option *ngFor="let school of schools" [value]="school.id" title="{{school.name}}" togglePosition="before">
                        {{school.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div class="pb-3">
                <b>Afbeeldingen</b>
                <div (cdkDropListDropped)="reorderImages($event)" cdkDropList class="images">
                    <div *ngFor="let image of article.images" cdkDrag class="image-edit">
                        <app-codalt-image [path]="article.id + '/' + image.path"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                        <button (click)="deleteImage(image)" color="primary" mat-mini-fab>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-arrows-alt-v"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2">
                    <file-manager
                        (callback)="uploadedImages($event)"
                        (loading)="uploadImages($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="article">
                    </file-manager>
                </div>
            </div>
            <div class="pb-3">
                <b>Video's</b>
                <div (cdkDropListDropped)="reorderVideos($event)" cdkDropList class="images">
                    <div *ngFor="let video of article.videos" cdkDrag class="image-edit">
                        <app-video [video]="video"></app-video>
                        <button (click)="deleteVideo(video)" color="primary" mat-mini-fab>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-arrows-alt-v"></i>
                        </div>
                    </div>
                    <div class="image-edit" *ngFor="let i of Utils.createRange(uploadingVideos)">
                        <div class="uploading">
                            <div class="text-center">
                                <i class="fa fa-spinner fa-spin"></i><br>
                                Bezig met uploaden
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2">
                    <file-manager
                        [reference]="FileTypes.video"
                        [referenceItem]="article.id"
                        (callback)="uploadedVideos($event)"
                        (loading)="uploadVideos($event)"
                        [directUpload]="true"
                        [video]="true"
                        [multiple]="false"
                        [path]="form.value.id"
                        secure="article">
                    </file-manager>
                </div>
            </div>
            <div class="pb-3">
                <b>PDF documenten</b>
                <table (cdkDropListDropped)="reorderDocuments($event)" cdkDropList>
                    <tr *ngFor="let document of article.documents" cdkDrag>
                        <td cdkDragHandle class="curmove">
                            <i class="fas fa-file-pdf-o"></i>
                        </td>
                        <td cdkDragHandle class="curmove">
                            {{document.path}}
                        </td>
                        <td>
                            <button (click)="deleteDocument(document)" color="primary" mat-icon-button>
                                <mat-icon class="fas fa-trash"></mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                <div class="mt-2 mb-2">
                    <file-manager
                        [reference]="FileTypes.document"
                        [hideCamera]="true"
                        (callback)="uploadedDocuments($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="articleDocument">
                    </file-manager>
                </div>
            </div>
        </form>

    </div>
    <div class="floating-buttons">
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
            <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
        </button>
    </div>

</div>
