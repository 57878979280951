<div class="container bg-white item-edit">
    <div>
        <h2 class="news__title">Kalender {{reserve ? 'toevoegen' : 'wijzigen'}}</h2>
    </div>
    <div *ngIf="form">
        <form [formGroup]="form">
            <div (click)="fileManagerMain.chooseSource()"
                 class="main-image">
                <app-codalt-image [path]="form.value.id+'/'+form.controls['image'].value"
                                  [thumb]="event.image_thumb"
                                  secureType="event">
                </app-codalt-image>
                <file-manager #fileManagerMain
                              (callback)="uploadedMainImage($event)"
                              (loading)="uploadMainImage($event)"
                              [class.text-white]="isCordova && form.controls['image'].value"
                              [currentFile]="form.controls['image'].value"
                              [directUpload]="true"
                              [path]="form.value.id"
                              [remove]="true"
                              icon="{{isCordova ? 'fas fa-image' : null}}"
                              secure="event">
                </file-manager>
            </div>
            <div class="row">
                <mat-form-field class="col-auto flex-grow-1 mt-3">
                    <mat-label>Titel</mat-label>
                    <input formControlName="title" matInput type="text">
                    <mat-error [control]="form.get('title')"></mat-error>
                </mat-form-field>
                <mat-checkbox class="col-auto mt-4" formControlName="alert">Belangrijk bericht</mat-checkbox>
            </div>
            <h2>Datum{{faDateranges.length > 1 ? 's' : ''}}</h2>
            <div *ngFor="let fgDateRange of faDateranges.controls; index as i" [formGroup]="fgDateRange" class="row align-items-center daterange">
                <mat-checkbox class="col-auto" formControlName="all_day">Hele dag</mat-checkbox>
                <div class="col curpoint order-md-last text-right text-md-left">
                    <i (click)="removeRange(i)" class="fa fa-trash"></i>
                </div>
                <ng-container *ngIf="!fgDateRange.get('all_day').value">
                    <div class="col col-date">
                        <mat-form-field (click)="pickerStart.open()" class="w-50">
                            <mat-label>Startdatum</mat-label>
                            <input [matDatepicker]="pickerStart" formControlName="start_date" matInput>
                            <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                            <mat-error [control]="fgDateRange.get('start_date')"></mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-50 pl-2">
                            <mat-label>Starttijd</mat-label>
                            <input formControlName="start_time" matInput type="text">
                            <mat-error [control]="fgDateRange.get('start_time')"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col col-date">
                        <mat-form-field (click)="pickerEnd.open()" class="w-50">
                            <mat-label>Einddatum</mat-label>
                            <input [matDatepicker]="pickerEnd" [min]="fgDateRange.get('start_date').value" formControlName="end_date" matInput>
                            <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                            <mat-error [control]="fgDateRange.get('end_date')"></mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-50 pl-2">
                            <mat-label>Eindtijd</mat-label>
                            <input formControlName="end_time" matInput type="text">
                            <mat-error [control]="fgDateRange.get('end_time')"></mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <mat-form-field (click)="picker.open()" *ngIf="!!fgDateRange.get('all_day').value" class="col col-daterange">
                    <mat-label>Datum</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input formControlName="start_date" matStartDate placeholder="Startdatum">
                        <input formControlName="end_date" matEndDate placeholder="Einddatum">
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <mat-error [control]="fgDateRange.get('start_date')"></mat-error>
                    <mat-error [control]="fgDateRange.get('end_date')"></mat-error>
                </mat-form-field>
            </div>
            <button (click)="addDaterange()" class="mt-3" mat-button mat-raised-button>Datum toevoegen</button>
            <br><br>
            <ng-container *ngIf="LocalStorage.school.type === 'offer'">
                <b>Naschools aanbod: </b><br>
                <div class="row mt-3">
                    <mat-form-field class="col-24 col-lg-9 col-md-12 col-xl-6">
                        <mat-label>E-mailadres organisator</mat-label>
                        <input formControlName="organizer_email" matInput type="text">
                        <mat-error [control]="form.get('organizer_email')"></mat-error>
                        <mat-hint>E-mailadres waarmee de organisator inlogt</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="col-24 col-lg-6 col-md-10 col-xl-5">
                        <mat-label>Prijs per kind</mat-label>
                        <input formControlName="price"
                               appDecimalInput
                               [allowNegative]="false"
                               decimalInput="2" matInput type="text">
                        <mat-hint *ngIf="faDateranges.value?.length > 1">
                            <span>Prijs per kind &nbsp;<strong>voor de gehele reeks</strong></span>
                        </mat-hint>
                        <mat-error [control]="form.get('price')"></mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-24 col-lg-8 col-md-12 col-xl-6">
                        <mat-label>Maximum aantal inschrijvingen</mat-label>
                        <input formControlName="max_subscriptions"
                               appDecimalInput
                               [allowNegative]="false"
                               decimalInput="2" matInput type="text">
                        <mat-hint>
                            Laat dit veld leeg voor geen limiet aan het aantal inschrijvingen
                        </mat-hint>
                        <mat-error [control]="form.get('max_subscriptions')"></mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-24 col-lg-9 col-md-10 col-xl-6" (click)="picker.open()">
                        <mat-label>Uiterste inschrijfdatum</mat-label>
                        <input [max]="(faDateranges.value | orderBy : 'start_date')[0]['start_date']" formControlName="register_before" matInput [matDatepicker]="picker">
                        <mat-hint>Datum waarna inschrijven niet meer mogelijk is</mat-hint>
                        <mat-error [control]="form.get('register_before')"></mat-error>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </ng-container>
            <div>
                <b>Artikel: </b>
                <simple-tiny [content]="form.get('content')"
                             elementId="content"
                             ngDefaultControl
                             propertyName="value"
                ></simple-tiny>
                <mat-error [control]="form.get('content')"></mat-error>
            </div>
            <div class="mt-2 mb-2">
                <b>Zichtbaarheid: </b>
                <div class="mt-2">
                    <mat-radio-group formControlName="access_level">
                        <mat-radio-button *ngIf="AuthorisationService.hasFeature('publicArticles')" class="mr-2" value="PUBLIC">Website &
                            App
                        </mat-radio-button>
                        <mat-radio-button class="mr-2" value="LOGGEDIN">App (ouders en medewerkers)</mat-radio-button>
                        <mat-radio-button class="mr-2" value="EMPLOYEE">App alleen medewerkers</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="!masterSchool && form.get('access_level')?.value !== 'PUBLIC' && form.get('access_level')?.value !== 'EMPLOYEE'">
                <b>Voor groepen: </b>
                <div class="mt-2">
                    <mat-radio-group formControlName="schoolWide" *ngIf="AuthorisationService.hasFeature('publicArticles')">
                        <mat-radio-button class="mr-2" [value]="true">Schoolbreed</mat-radio-button>
                        <mat-radio-button class="mr-2" [value]="false">Specifieke groepen</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-selection-list *ngIf="classrooms && !form.get('schoolWide').value" class="classroom-list" formControlName="classrooms">
                    <mat-list-option *ngFor="let classroomGroup of classroomGroups" [value]="'group_' + classroomGroup" checkboxPosition="before">
                        {{classroomGroup}}
                    </mat-list-option>
                    <mat-list-option *ngFor="let classroom of classrooms"
                                     matTooltip="Dit is een groep van een voorgaand schooljaar en kan niet meer worden aangepast."
                                     [matTooltipDisabled]="!classroom.deleted_at"
                                     [disabled]="classroom.deleted_at"
                                     [value]="classroom.id"
                                     togglePosition="before">
                        {{classroom.classroom_name}}
                    </mat-list-option>
                </mat-selection-list>
                <mat-error *ngIf="form.get('classrooms').touched" [control]="form.get('classrooms')"></mat-error>
            </div>
            <div *ngIf="masterSchool">
                <b>Toon dit bericht bij: </b><br>
                <mat-selection-list formControlName="schools" class="school-list" *ngIf="schools">
                    <mat-list-option *ngFor="let school of schools" [value]="school.id" title="{{school.name}}" togglePosition="before">
                        {{school.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div class="pb-3">
                <b>Afbeeldingen</b>
                <div (cdkDropListDropped)="reorderImages($event)" cdkDropList class="images">
                    <div *ngFor="let image of event.images" cdkDrag class="image-edit">
                        <app-codalt-image [path]="event.id + '/' + image.path"
                                          [thumb]="image.thumb"
                                          secureType="event">
                        </app-codalt-image>

                        <button (click)="deleteImage(image)" color="primary" mat-mini-fab>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-arrows-alt-v"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2">
                    <file-manager
                        (callback)="uploadedFiles($event)"
                        (loading)="uploadFiles($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="event">
                    </file-manager>
                </div>
            </div>
            <div class="pb-3">
                <b>PDF documenten</b>
                <table (cdkDropListDropped)="reorderDocuments($event)" cdkDropList>
                    <tr *ngFor="let document of event.documents" cdkDrag>
                        <td cdkDragHandle class="curmove">
                            <i class="fas fa-file-pdf-o"></i>
                        </td>
                        <td cdkDragHandle class="curmove">
                            {{document.path}}
                        </td>
                        <td>
                            <button (click)="deleteDocument(document)" color="primary" mat-icon-button>
                                <mat-icon class="fas fa-trash"></mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                <div class="mt-2 mb-2">
                    <file-manager
                        [hideCamera]="true"
                        (callback)="uploadedDocuments($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="eventDocument">
                    </file-manager>
                </div>
            </div>
        </form>

    </div>
    <div class="floating-buttons">
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
            <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
        </button>
    </div>
</div>
