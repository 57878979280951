<div class="dialog-container">
    <div class="dialog-content">
        <div class="title d-flex justify-content-between align-items-center">
            <div>
                {{chat.id ? 'Chat aanpassen' : 'Nieuwe chat'}}
            </div>
            <button class="close" mat-dialog-close="" mat-icon-button tabindex="-1">
                <mat-icon class="fas fa-close"></mat-icon>
            </button>
        </div>
        <form [formGroup]="form">
            <div class="d-flex flex-column form-content">
                <div class="content pb-0">
                    <mat-form-field class="w-100 mb-0 title-input">
                        <mat-label>Titel</mat-label>
                        <input formControlName="name" matInput placeholder="Naam van de chat" type="text">
                        <mat-error [control]="form.get('name')"></mat-error>
                    </mat-form-field>
                    <div *ngIf="!chat.id && classrooms?.length" class="d-flex align-items-center justify-content-between mb-1">
                        <mat-radio-group aria-label="Chatten met" formControlName="type">
                            <mat-radio-button class="mr-1" value="users">Individuele personen</mat-radio-button>
                            <mat-radio-button value="classrooms">Groepen</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-form-field class="w-100 search-input">
                        <mat-label>Zoeken...</mat-label>
                        <input formControlName="search" matInput placeholder="Zoek op naam van een gebruiker" type="text">
                    </mat-form-field>
                </div>
                <mat-selection-list (selectionChange)="selectionChange($event)"
                                    *ngIf="fcType.value == 'users'"
                                    [formControl]="fcUsersTemp"
                                    class="flex-fill overflow-auto p-0">
                    <div mat-subheader class="mt-0">Gebruikers toevoegen of verwijderen</div>
                    <div mat-subheader *ngIf="loading">
                        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
                    </div>
                    <ng-container *ngFor="let user of users | chatSearch:form.get('search').value">
                        <mat-list-option *ngIf="LocalStorage.user.id !== user.id"
                                         [value]="user.id"
                                         checkboxPosition="before">
                            <div>
                                {{user.name}}
                                <span *ngIf="user.students"> - Ouder/verzorger</span>
                                <span *ngIf="user.classrooms?.length > 0"> - Leerkracht</span>
                            </div>
                            <div *ngIf="user.function_one" class="sub-line">
                                {{user.function_one}}{{user.function_two ? ', ' : ''}}{{user.function_two}}
                            </div>
                            <div class="sub-line">
                                <span *ngFor="let school of user.schools; let last = last">
                                    {{school.name}} {{last ? '' : ', '}}
                                </span>
                                <span *ngFor="let classroom of user.classrooms; let last = last">
                                    {{classroom.classroom_name}} {{last ? '' : ', '}}
                                </span>
                                <ng-container *ngFor="let student of user.students; let lastS = last">
                                    <span *ngFor="let classroom of student.classrooms;">
                                        {{student.name}} ({{classroom.classroom_name}}){{lastS ? '' : ', '}}
                                    </span>
                                </ng-container>
                            </div>
                        </mat-list-option>
                    </ng-container>
                </mat-selection-list>
                <mat-selection-list *ngIf="fcType.value == 'classrooms'" class="flex-fill overflow-auto p-0" formControlName="classrooms">
                    <ng-container *ngFor="let classroom of classrooms | search:'classroom_name':form.get('search').value">
                        <mat-list-option [value]="classroom.id" checkboxPosition="before">
                            <div>{{classroom.classroom_name}}</div>
                            <div class="sub-line">
                                {{classroom.school.name}}
                            </div>
                        </mat-list-option>
                    </ng-container>
                </mat-selection-list>
            </div>
        </form>
        <button (click)="startChat()" [disabled]="form.invalid" color="primary" mat-fab>
            <mat-icon class="far fa-comments"></mat-icon>
        </button>
    </div>
</div>
