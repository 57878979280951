import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {PermissionService} from '../../services/permission.service';
import {Classroom} from '../../classes/classroom.class';
import {Permission} from '../../classes/permission';
import {PermissionQuestion} from '../../classes/permission-question';

@Component({
    selector: 'app-permission-stats',
    templateUrl: './permission-stats.component.html',
    styleUrls: ['./permission-stats.component.scss']
})
export class PermissionStatsComponent extends CodaltComponent implements OnInit {

    classrooms: Classroom[];
    question: PermissionQuestion;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private classroomService: ClassroomService,
                private permissionService: PermissionService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe((params: {id: number}) => {
            this.subscriptions.add(this.permissionService.getOnePermissionWithStudents(params.id).subscribe(permissions => {
                this.question = permissions.data[0];
            }));
            this.subscriptions.add(this.classroomService.getClassrooms().subscribe(classrooms => {
                this.classrooms = classrooms.data;
            }));
        }));
    }

    info(question: PermissionQuestion) {
        let content = `${question.description?.replace('\n', '<br>')}<br><br>`;
        question.options.forEach(level => {
            content += `<b>${level.name}</b> <i>${level.intro || ''}</i><br>`;
            content += `<p>${(level.description || '')?.replace('\n', '<br>')}</p>`;
        });

        this.confirmDialog.confirm(question.name,
            content, 'Sluiten', null, '600px').then(() => {
        });
    }
}
